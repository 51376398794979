import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

//SERVICE
import { LoginService } from "src/app/_service/publico/login.service";

//MODEL
import { LoginInput } from 'src/app/_model/publico/login_input';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  usuario: string;
  clave: string;
  mensaje: string;
  error: string;
  spinnerVisible = false;
  isSubmit: boolean;

  constructor(private loginService: LoginService,
    private router: Router) {
    this.isSubmit = false;
  }

  ngOnInit() {
  }

  login(form: any) {

    if (!form.valid) {
      this.isSubmit = true;
      return;
    }

    this.spinnerVisible = true;
    let content = new LoginInput();
    content.codigoUsuarioSesion = this.usuario;
    content.password = this.clave;
    this.loginService.login(content).subscribe(data => {
      this.spinnerVisible = false;
      if (data.estado == '1') {
        this.mensaje = data.descripcion;
        this.loginService.logueado = true;
        this.loginService.usuario = this.usuario.toUpperCase().trim();
        this.router.navigate(['dashboard']);
      } else {
        this.error = data.descripcion;
        this.loginService.logueado = false;
      }

    }, error => {
      this.spinnerVisible = false;
      this.error = 'Ocurrió un error al intentar iniciar sesión.'
      this.loginService.logueado = false;
    });
    this.error = null;
    this.mensaje = null;
  }

}

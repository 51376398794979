import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  @Input() model: NgbDateStruct;
  @Output() fechaSeleccionada = new EventEmitter();
  placement = 'bottom';
  constructor() { }

  ngOnInit(): void {
    // this.model  = this.calendar.getToday();
  }

  cambiarFecha() {
    // console.log(this.model)
    // console.log(this.calendar.getToday())
    this.fechaSeleccionada.emit(this.model);
    console.log(this.model);
  }

}

import { Component, OnInit } from '@angular/core';
import {IOption} from 'ng-select';

@Component({
  selector: 'app-frm-select',
  templateUrl: './frm-select.component.html',
  styleUrls: ['./frm-select.component.scss']
})
export class FrmSelectComponent implements OnInit {

  selectedCar = '3';

  cars: Array<IOption> = [
    {value: '0', label: 'Alabama'},
    {value: '1', label: 'Wyoming'},
    {value: '2', label: 'Coming'},
    {value: '3', label: 'Henry Die'},
    {value: '4', label: 'John Doe'}
  ];

  constructor() { }

  ngOnInit(): void {
  }

}

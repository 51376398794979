import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { AuthGuard } from "./guards/auth.guard";
import { Not403Component } from './pages/mantenimiento/not403/not403.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(module => module.DashboardModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'menu/contrato',
        loadChildren: () => import('./pages/contrato/contrato.module').then(module => module.ContratoModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'menu/contrato/edicion',
        loadChildren: () => import('./pages/contrato/contrato-edicion/contrato-edicion.module').then(module => module.ContratoEdicionModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'menu/contrato/edicion/:local/:anio/:tipo/:nro',
        loadChildren: () => import('./pages/contrato/contrato-edicion/contrato-edicion.module').then(module => module.ContratoEdicionModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'menu/contrato/productos/:local/:anio/:tipo/:nro/:etapa',
        loadChildren: () => import('./pages/contrato/contrato-productos/contrato-productos.module').then(module => module.ContratoProductosModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'menu/maestros/formas-pago',
        loadChildren: () => import('./pages/maestros/formas-pago/formas-pago.module').then(module => module.FormasPagoModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'menu/maestros/personas',
        loadChildren: () => import('./pages/maestros/personas/personas.module').then(module => module.PersonasModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'menu/maestros/personas/edicion',
        loadChildren: () => import('./pages/maestros/personas/persona-editor/persona-editor.module').then(module => module.PersonaEditorModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'menu/maestros/personas/edicion/:persona',
        loadChildren: () => import('./pages/maestros/personas/persona-editor/persona-editor.module').then(module => module.PersonaEditorModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'menu/maestros/puertos',
        loadChildren: () => import('./pages/maestros/puertos/puertos.module').then(module => module.PuertosModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'menu/maestros/ejecutivos',
        loadChildren: () => import('./pages/maestros/ejecutivos/ejecutivos.module').then(module => module.EjecutivosModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'menu/maestros/especies',
        loadChildren: () => import('./pages/maestros/especies/especies.module').then(module => module.EspeciesModule),
        canActivate: [AuthGuard]
      }

    ]
  },
  {
    path: 'login',
    component: AuthComponent,
    children: []
  },
  {
    path: 'not-403',
    component: Not403Component,
    children: []
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'navigation',
    title: 'Navigation',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        url: '/dashboard',
        classes: 'nav-item',
        icon: 'feather icon-sidebar'
      },
      {
        id: 'menu',
        title: 'Menu',
        type: 'collapse',
        icon: 'feather icon-menu',
        children: [
          {
            id: 'contrato',
            title: 'Contracts',
            type: 'item',
            //url: 'javascript:',
            url: '/menu/contrato'
            // external: true
          },
          {
            id: 'maestros',
            title: 'Maintenance',
            type: 'collapse',
            children: [
              {
                id: 'personas',
                title: 'Seller/Buyer',
                type: 'item',
                url: '/menu/maestros/personas'
               
              },
              {
                id: 'formas-pago',
                title: 'Payment Terms',
                type: 'item',
                url: '/menu/maestros/formas-pago'
       
              },
              {
                id: 'puertos',
                title: 'Ports',
                type: 'item',
                url: '/menu/maestros/puertos'
       
              },
              {
                id: 'ejecutivos',
                title: 'Executives',
                type: 'item',
                url: '/menu/maestros/ejecutivos'
       
              },
              {
                id: 'especies',
                title: 'Species',
                type: 'item',
                url: '/menu/maestros/especies'
       
              }
            ]
          }

          /*,
          {
            id: 'menu-level-2.2',
            title: 'Menu Level 2.2',
            type: 'collapse',
            children: [
              {
                id: 'menu-level-2.2.1',
                title: 'Menu Level 2.2.1',
                type: 'item',
                url: 'javascript:',
                external: true
              },
              {
                id: 'menu-level-2.2.2',
                title: 'Menu Level 2.2.2',
                type: 'item',
                url: 'javascript:',
                external: true
              }
            ]
          }*/
        ]
      }
    ]
  }
];

@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }
}

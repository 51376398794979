<form class="form-inline">
    <div class="form-group">
        <div class="input-group">
            <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="model" ngbDatepicker
                #d="ngbDatepicker" [placement]="placement" [positionTarget]="buttonEl" (ngModelChange)="cambiarFecha()">
            <div class="input-group-append">
                <span #buttonEl class="input-group-text" (click)="d.toggle()">
                    <i class="fa fa-calendar"></i>
                </span>
            </div>
        </div>
    </div>
</form>